import Image from 'next/image';
import { MdArrowForward } from 'react-icons/md';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import useTranslation from 'next-translate/useTranslation';

import MPlanCard from 'components/molecules/MPlanCard';

const OPricingSection = () => {
  const { t } = useTranslation('common');
  return (
    <Box pt={{ xs: 12, md: 40 }} pb={{ xs: 0, md: 30 }} className="home-plans">
      <Container>
        <h2 className="u-text-center">{t('oPricingSection.startBasic')}</h2>
        <Box mt={{ xs: 9, md: 25 }}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Box mr={{ xs: 0, md: 8 }} pb={{ xs: 12, md: 0 }} minHeight={550}>
                <div className="home-plan-card">
                  <h3 className="home-plan-card__title">{t('oPricingSection.simplePlans')}</h3>
                  <p className="o-body-regular1 home-plan-card__desc">{t('oPricingSection.chooseAPlan')}</p>
                  <Box
                    width={{ xs: 24, md: 36 }}
                    height={{ xs: 24, md: 36 }}
                    mt={{ xs: 8, md: 12 }}
                    mb={{ xs: 42, md: 25 }}
                    position="relative"
                  >
                    <MdArrowForward size={30} color="white" />
                  </Box>
                  <Box mx={{ xs: 5, md: 10 }} height={{ xs: 270, md: 300 }} position="relative">
                    <Image src="/assets/illustration.png" alt="Illustration" layout="fill" objectFit="contain" />
                  </Box>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box mx={{ xs: 0, md: 8 }} pb={{ xs: 12, md: 0 }} minHeight={550} height="100%">
                <MPlanCard
                  title={t('oPricingSection.free')}
                  desc={t('oPricingSection.ourMostBasic')}
                  services={[
                    t('oPricingSection.withWatermark'),
                    t('oPricingSection.basicInformation'),
                    t('oPricingSection.education'),
                    t('oPricingSection.experience'),
                  ]}
                  price={0}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box ml={{ xs: 0, md: 8 }} pb={{ xs: 12, md: 0 }} minHeight={550} height="100%">
                <MPlanCard
                  title={t('oPricingSection.premium')}
                  desc={t('oPricingSection.ourPremium')}
                  services={[
                    t('benefits.removeWatermark'),
                    t('benefits.hundredPlus'),
                    t('benefits.fiftyPlus'),
                    t('benefits.expertTips'),
                    t('benefits.optimisedFor'),
                  ]}
                  price={5}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default OPricingSection;
