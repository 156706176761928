import { FC } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { AppState, LoginData } from 'redux-saga/interfaces';

interface MPlanCardProps {
  title: string;
  desc: string;
  services: string[];
  price: number;
}

const MPlanCard: FC<MPlanCardProps> = ({ title = '', desc = '', services = [], price = 0 }) => {
  const { token } = useSelector<AppState, LoginData>((state) => state.userInfo);
  const isLoggedIn = !!token;
  const router = useRouter();

  const { t } = useTranslation('common');
  return (
    <div className="c-plan-card">
      <div>
        <h3 className="c-plan-card__title">{title}</h3>
        <div className="o-body-regular2">
          <p className="c-plan-card__desc">{desc}</p>
          {services.map((service) => (
            <div className="c-plan-card__service" key={service}>
              <Box component="span" width={16} height={16} mr={4}>
                <Image src="/assets/svg/checklist.svg" alt="Checklist" width={16} height={16} />
              </Box>
              {service}
            </div>
          ))}
        </div>
        <div className="c-plan-card__line" />
        <div className="c-plan-card__money">
          {price > 0 && <div>{t('mPlanCard.from')}</div>}
          <Box mx={3} fontSize={18}>
            $
          </Box>
          <div className="c-plan-card__price">{price}</div>
          {price > 0 && <div>{t('mPlanCard.onwards')} </div>}
        </div>
      </div>
      <div>
        <Button
          variant="contained"
          fullWidth
          disableElevation
          className="c-primary-button"
          color="primary"
          onClick={() => router.push(isLoggedIn ? '/my-cvs' : '/register')}
        >
          {`${t('mPlanCard.getStarted')} ${title}`}
        </Button>
      </div>
    </div>
  );
};

export default MPlanCard;
